<template>
  <section class="basket">
    <b-row v-if="items.length">
      <b-col class="mb-3" md="8">
        <div class="basket-block">
          <div class="basket__product-wrapper">
            <BasketProduct v-for="item in items" :key="item.leftover_id" :item="item" @delete="deleteProduct"
              @increment="incrementProductsQuantity" @decrement="decrementProductsQuantity"
              @setQuantity="setProductsQuantity" />
          </div>
        </div>

      </b-col>
      <b-col md="4">
        <div class="basket-block">
          <div class="font-weight-bold">Итого</div>
          <div class="basket-block__total font-weight-bold">
            {{ promoPrice ? promoPrice : totalSum }} ₸
            <span v-if="promoPrice" class="basket-block__discount-price ml-2"> {{ totalSum }} ₸</span>
          </div>
          <div class="basket-block__info mt-3">
            <div class="d-flex justify-content-between mb-2">
              <span>Товары, {{ totalQuantity }} шт.</span>
              <span>{{ totalProductsSum }}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>Скидка:</span>
              <span>Нет</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span>Стоимость упаковки:</span>
              <span> {{ packageCost }} ₸</span>
            </div>
            <div class="d-flex justify-content-between mb-4">
              <span class="mr-2">Доставка:</span>
              <span class="text-right"> {{ deliveryPrice ? `${deliveryPrice} ₸` : 'Оплата при получении' }} </span>
            </div>
            <div class="d-flex font-weight-bold mb-2">
              <span class="mr-3">Дата доставки:</span>
              <span> {{ formattedDeliveryDate }} </span>
            </div>
            <div class="d-flex font-weight-bold mb-4">
              <span class="mr-3">Адрес:</span>
              <span class="font-weight-bold"> {{ selectedAddress ? selectedAddress.name : 'Не выбран' }} </span>
            </div>
            <form class="basket__form mt-4 mb-3" ref="basketFormRef">
              <b-form-group id="input-group-8" label="Способ доставки:">
                <b-form-checkbox v-for="deliveryType in deliveryTypes" :key="deliveryType.id"
                  v-model="selectedDeliveryType" :value="deliveryType.id" :state="validateDeliveryType" inline>
                  {{ deliveryType.name }}
                </b-form-checkbox>
                <b-form-invalid-feedback :state="validateDeliveryType">Выберите способ доставки</b-form-invalid-feedback>
              </b-form-group>
              <b-form-checkbox class="mb-3" v-model="isDeliveryZoneRegion" :value="true" inline>
                Доставка осуществляется в регион?
              </b-form-checkbox>
              <b-form-group id="input-group-1" label="Номер телефон:" label-for="input-1">
                <b-form-input v-model.trim="phone" id="input-1" type="tel" placeholder="+7 ("
                  v-mask="'+7 (###) ### ## ##'" required :state="validatePhone"></b-form-input>
                <b-form-invalid-feedback :state="validatePhone">
                  Введите корректный номер телефона
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="input-group-3" label="Полное имя:" label-for="input-3">
                <b-form-input v-model.trim="fullName" id="input-3" type="text" :state="validateFullName"
                  placeholder="Введите ФИО"></b-form-input>
                <b-form-invalid-feedback :state="validateFullName">
                  Введите корректное имя и фамилию
                </b-form-invalid-feedback>
              </b-form-group>
              <b-form-group id="input-group-6" label="Email:" label-for="input-6">
                <b-form-input v-model.trim="email" id="input-6" type="text" placeholder="Введите email"></b-form-input>
              </b-form-group>
              <b-form-group id="input-group-9" label="Адрес доставки:" label-for="input-9">
                <b-form-select v-model="selectedAddressId" :options="addressList" @change="changedSelectedAddress"
                  :state="validateSelectedAddress"></b-form-select>
                <b-form-invalid-feedback :state="validateSelectedAddress">
                  Выберите адрес доставки
                </b-form-invalid-feedback>
              </b-form-group>
              <!--          <b-form-group id="input-group-7" label="Способ оплаты:">-->
              <!--            <b-form-checkbox v-model="paymentType" :value="2" :state="validatePaymentType" inline-->
              <!--              >Оплата картой</b-form-checkbox-->
              <!--            >-->
              <!--            <b-form-checkbox v-model="paymentType" :value="1" :state="validatePaymentType" inline-->
              <!--              >Оплата при получении</b-form-checkbox-->
              <!--            >-->
              <!--            <b-form-invalid-feedback :state="validatePaymentType">Выберите способ оплаты</b-form-invalid-feedback>-->
              <!--          </b-form-group>-->
            </form>
            <div class="promo mb-3">
              <b-input v-model="promoCode" class="promo__input" :disabled="!!promoPrice" autocomplete="off"
                placeholder="Промо-код"></b-input>
              <button @click="getPromoDiscount" class="button promo__button" :disabled="promoPrice || !promoCode.length">
                Применить
              </button>
            </div>
            <div class="mb-1">
              <button @click="order()" class="button product__favorite-button w-100">Оформить заявку</button>
            </div>
            <div class="agreement">
              <b-form-checkbox id="checkbox-1" v-model="isRulesAccepted" name="checkbox-1" :value="true"
                :unchecked-value="false">
                Согласен с условиями Правил пользования торговой площадкой и правилами возврата
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </b-col>
    </b-row>
    <div v-else class="basket__empty text-center mt-3">В корзине нету товаров</div>

    <a ref="payLink" :href="payUrl" target="_blank" style="display: none"></a>

    <b-modal v-model="showCreateAddressModal" id="create-address-modal" centered title="Создать адрес" hide-footer>
      <div class="mb-3">
        <b-input v-model.trim="addressTitle" placeholder="Как сохранить адрес?" autocomplete="off"
          :state="validateAddressTitle"></b-input>
        <b-form-invalid-feedback :state="validateAddressTitle"> Введите как сохранить адрес </b-form-invalid-feedback>
      </div>
      <div class="mb-3">
        <b-input v-model.trim="postIndex" placeholder="Почтовый индекс" type="text" v-mask="'######'" autocomplete="off"
          :state="validatePostCode"></b-input>
        <b-form-invalid-feedback :state="validatePostCode"> Введите корректный почтовый код </b-form-invalid-feedback>
      </div>
      <div class="mb-3">
        <b-input v-model.trim="fullAddress" :state="validateFullAddress" placeholder="Введите полный адрес" type="text"
          autocomplete="off"></b-input>
        <b-form-invalid-feedback :state="validateFullAddress"> Введите полный адрес </b-form-invalid-feedback>
      </div>
      <b-button @click="addAddress" variant="success">Создать</b-button>
    </b-modal>
  </section>
</template>

<script>
import Api from '@/services/api'
import BasketProduct from '@/views/basket/components/BasketProduct'
import { mask } from 'vue-the-mask'

export default {
  name: 'BasketPage',
  directives: { mask },
  components: {
    BasketProduct,
  },

  data: () => ({
    showCreateAddressModal: false,
    addressTitle: '',
    fullAddress: '',
    isRulesAccepted: false,
    payUrl: '',
    phone: '',
    email: '',
    fullName: '',
    postIndex: '',
    isTouched: null,
    deliveryTypes: [],
    selectedAddressId: null,
    selectedDeliveryType: null,
    isAddressTouched: null,
    deliveryPrice: 0,
    deliveryDate: '',
    promoCode: '',
    promoPrice: 0,
    isDeliveryZoneRegion: false,
    minOrderSum: 0,
    packageCost: 0,
  }),

  computed: {
    user() {
      return this.$store.state.user
    },

    isOrderAble() {
      return this.totalProductsSum >= Number(this.minOrderSum)
    },

    items() {
      return this.$store.state.products.basket
    },

    // validatePaymentType() {
    //   if (!this.isTouched) return null
    //   return !!this.paymentType
    // },

    validateDeliveryType() {
      if (!this.isTouched) return null
      return !!this.selectedDeliveryType
    },

    validatePhone() {
      if (!this.isTouched) return null
      return this.phone.length === 18
    },

    validatePostCode() {
      if (!this.isAddressTouched) return null
      return this.postIndex.length === 6
    },

    validateAddressTitle() {
      if (!this.isAddressTouched) return null
      return this.addressTitle.length > 1
    },

    validateFullAddress() {
      if (!this.isAddressTouched) return null
      return this.fullAddress.length > 1
    },

    validateSelectedAddress() {
      if (!this.isTouched) return null
      return !!this.selectedAddress
    },

    validateFullName() {
      if (!this.isTouched) return null
      return this.fullName.length > 1
    },

    totalProductsSum() {
      if (!this.items.length) return 0
      return this.items.reduce((accumulate, item) => (accumulate += item.quantity * Number(item.product.price)), 0)
    },

    totalSum() {
      if (!this.items.length) return 0
      const total =
        this.items.reduce((accumulate, item) => (accumulate += item.quantity * Number(item.product.price)), 0) +
        this.deliveryPrice +
        this.packageCost
      return Number(total).toFixed(2)
      // return new Intl.NumberFormat('ru-RU', { style: 'decimal' }).format(total)
    },

    totalQuantity() {
      if (!this.items.length) return 0
      return this.items.reduce((accumulate, item) => (accumulate += item.quantity), 0)
    },

    addresses() {
      return this.$store.state.addresses
    },

    formattedDeliveryDate() {
      if (!this.deliveryDate) return 'Неизвестно'
      return new Intl.DateTimeFormat('ru-RU', {
        year: 'numeric',
        month: 'long',
        day: '2-digit',
      }).format(new Date(this.deliveryDate))
    },

    addressList() {
      if (!this.addresses || !this.addresses.length) return []
      const list = this.addresses.map((el) => {
        return {
          text: el.name,
          value: el.id,
        }
      })

      list.unshift({
        text: 'Выберите адрес доставки',
        value: null,
      })

      list.push({
        text: '+ Добавить новый адрес доставки',
        value: 0,
      })
      return list
    },

    selectedAddress() {
      if (this.$store.state.addresses.length)
        return this.$store.state.addresses.find((el) => el.id === this.selectedAddressId)
      return null
    },

    selectedCity() {
      return this.$store.state.selectedCity
    },
  },

  async mounted() {

    let recaptchaScript = document.createElement('script')
    recaptchaScript.setAttribute('src', 'http://widget.cloudpayments.ru/bundles/cloudpayments.js')
    document.head.appendChild(recaptchaScript)


    const { delivery_types } = await this.wrapAndHandleRequest(Api.fetchDeliveryTypes)
    this.deliveryTypes = delivery_types
    const { cart } = await this.wrapAndHandleRequest(Api.fetchCartList)
    this.$store.commit('products/SET_BASKET_PRODUCTS', cart)

    const { addresses } = await this.wrapAndHandleRequest(Api.fetchAddressList, false)
    this.$store.commit('SET_ADDRESSES', addresses)

    const { min_order_sum } = await this.wrapAndHandleRequest(Api.getMinOrderSum, false)
    this.minOrderSum = min_order_sum

    const { package_cost } = await this.wrapAndHandleRequest(Api.getPackageCost, false)
    this.packageCost = Number(package_cost)

    if (this.user) {
      this.fillUsersData()
    }

    if (this.$route.query.promo) {
      this.promoCode = this.$route.query.promo
      await this.getPromoDiscount()
    }
  },

  methods: {
    payy(id, price_) {

      var widget = new window.cp.CloudPayments();
      widget.pay('auth', // или 'charge'
        { //options
          publicId: 'pk_22ee69f0d5e41cf370a20a5f7f05b', //id из личного кабинета
          description: 'Оплата товаров в example.com', //назначение
          amount: price_, //сумма
          currency: 'KZT', //валюта
          accountId: 'user@example.com', //идентификатор плательщика (необязательно)
          invoiceId: id, //номер заказа  (необязательно)
          email: 'user@example.com', //email плательщика (необязательно)
          skin: "mini", //дизайн виджета (необязательно)
          autoClose: 3, //время в секундах до авто-закрытия виджета (необязательный)
          data: {
            myProp: 'myProp value'
          },
          configuration: {
            common: {
              successRedirectUrl: "http://localhost/success", // адреса для перенаправления 
              failRedirectUrl: "https://localhost/fail"        // при оплате по Tinkoff Pay
            }
          },
          payer: {
            firstName: this.name,
            lastName: this.fullName,
            middleName: 'Тестович',
            birth: '1955-02-24',
            address: 'тестовый проезд дом тест',
            street: 'Lenina',
            city: 'MO',
            country: 'RU',
            phone: this.phone,
            postcode: '345'
          }
        },
        {
          onSuccess: (options) => { // success
            //действие при успешной оплате
            console.log('options2')
            this.wrapAndHandleRequest(() => Api.SuccessToServ({ transactionId: parseInt(options.invoiceId) }), false).then((e) => {
                console.log('eeee')
                console.log(e)
              }).catch((err)=>{
                console.log('err')
                console.log(err)
              })



            this.$router.push('/payment-success')
          },
          onFail: (reason, options) => { // fail
            console.log('reason')
            console.log(reason)
            this.$router.push('/payment-fail')
            console.log(options)

            //действие при неуспешной оплате
          },
          onComplete: function (paymentResult, options) { //Вызывается как только виджет получает от api.cloudpayments ответ с результатом транзакции.
            console.log("paymentResult2222")
            console.log(paymentResult)

            console.log("paymentResult.success2222")
            console.log(paymentResult.success)

            if (paymentResult.success) {
              console.log("options.invoiceId22222")
            console.log(options.invoiceId)
            console.log("this.wrapAndHandleRequest(")
              this.wrapAndHandleRequest(() => Api.SuccessToServ({ transactionId: parseInt(options.invoiceId) }), false).then((e) => {
                console.log('eeee')
                console.log(e)
              }).catch((err)=>{
                console.log('err')
                console.log(err)
              })
            }
            console.log(options)



            //например вызов вашей аналитики
          }
        }
      )
    },
    fillUsersData() {
      const user = this.user
      this.phone = user.phone
      if (user.fullname !== user.phone) {
        this.fullName = user.fullname
      }

      this.$nextTick(() => {
        this.phone = this.phone + ' '
      })
    },

    async getPromoDiscount() {
      if (!this.promoCode.length) return
      if (this.$route.query.promo && this.$route.query.promo != this.promoCode) {
        await this.$router.push({ query: { promo: this.promoCode } })
      }
      const data = []
      this.items.forEach((el) => {
        data.push({ leftover_id: el.leftover_id, quantity: el.quantity })
      })
      if (!data.length) return
      try {
        const response = await this.wrapAndHandleRequest(() =>
          Api.getPromoDiscount({
            cart: JSON.stringify(data),
            promo: this.promoCode,
          }),
        )
        console.log(response)
        if (response && response.promo_amount === 0) {
          this.promoCode = ''
          return this.makeToast('Применение промо-кода', 'Срок действия вашего промо-кода истек', 'info', true)
        }
        if (response && response.promo_amount) {
          this.promoPrice = response.promo_amount
        }
      } catch (e) {
        const status = e?.response?.status
        if (status && status === 404) {
          this.makeToast('Применение промо-кода', 'Введенный вами промо-код не найден в базе', 'danger', true)
        }
      }
    },

    async deleteProduct(leftover_id) {
      const data = []
      this.items.forEach((el) => {
        if (el.leftover_id === leftover_id) return
        data.push({ leftover_id: el.leftover_id, quantity: el.quantity })
      })
      await this.wrapAndHandleRequest(() => Api.updateCart({ cart: JSON.stringify(data) }), true)
      const { cart } = await this.wrapAndHandleRequest(Api.fetchCartList)
      this.$store.commit('products/SET_BASKET_PRODUCTS', cart)
      this.$nextTick(async () => {
        await this.getPromoDiscount()
      })
    },

    validateData() {
      return this.validatePhone && this.validateFullName && this.validateDeliveryType && this.selectedAddress
    },

    async setProductsQuantity({ leftover_id, quantity }) {
      quantity = Number(quantity)
      const data = []
      this.items.forEach((el) => {
        data.push({
          leftover_id: el.leftover_id,
          quantity: el.leftover_id === leftover_id ? (el.quantity = quantity) : el.quantity,
        })
      })
      await this.wrapAndHandleRequest(() => Api.updateCart({ cart: JSON.stringify(data) }), true)
      const product = this.items.find((el) => el.leftover_id === leftover_id)
      product.quantity = quantity
      this.$nextTick(async () => {
        await this.getPromoDiscount()
      })
    },

    async incrementProductsQuantity(leftover_id) {
      const data = []
      this.items.forEach((el) => {
        data.push({
          leftover_id: el.leftover_id,
          quantity: el.leftover_id === leftover_id ? el.quantity + 1 : el.quantity,
        })
      })
      await this.wrapAndHandleRequest(() => Api.updateCart({ cart: JSON.stringify(data) }), true)
      const product = this.items.find((el) => el.leftover_id === leftover_id)
      ++product.quantity
      await this.getOrderDeliveryDateAndPrice()
      this.$nextTick(async () => {
        await this.getPromoDiscount()
      })
    },

    async decrementProductsQuantity(leftover_id) {
      const product = this.items.find((el) => el.leftover_id === leftover_id)
      if (product.quantity === 1) {
        await this.deleteProduct(leftover_id)
        return
      }
      const data = []
      this.items.forEach((el) => {
        data.push({
          leftover_id: el.leftover_id,
          quantity: el.leftover_id === leftover_id ? el.quantity - 1 : el.quantity,
        })
      })
      await this.wrapAndHandleRequest(() => Api.updateCart({ cart: JSON.stringify(data) }), true)
      --product.quantity
      await this.getOrderDeliveryDateAndPrice()
      this.$nextTick(async () => {
        await this.getPromoDiscount()
      })
    },

    showAddAddressModal() {
      this.showCreateAddressModal = true
    },

    validateAddressData() {
      return this.validatePostCode && this.validateAddressTitle && this.validateFullAddress
    },

    async addAddress() {
      this.isAddressTouched = true
      if (!this.validateAddressData()) return
      if (!this.selectedCity) {
        return this.makeToast(
          'Адрес',
          'Перед тем как добавить адрес доставки, выберите город в правом верхнем углу',
          'warning',
        )
      }
      const data = {
        name: this.fullAddress,
        title: this.addressTitle,
        cityId: this.selectedCity.id,
        postIndex: this.postIndex,
        status: 1,
      }
      const { address } = await this.wrapAndHandleRequest(() => Api.postAddress(data))
      this.addressTitle = ''
      this.fullAddress = ''
      this.postIndex = ''
      this.$store.commit('ADD_ADDRESS', address)
      this.isAddressTouched = null
      this.showCreateAddressModal = false
    },

    changedSelectedAddress(id) {
      if (id !== 0) return
      this.showAddAddressModal()
      this.selectedAddressId = null
    },

    async order() {
      if (!this.isOrderAble)
        return this.makeToast('Сумма заказа', `Минимальная сумма заказа: ${this.minOrderSum} ₸`, 'warning', false)

      if (!this.isRulesAccepted)
        return this.makeToast(
          'Правила',
          'Вам нужно согласиться с условиями и правилами торговой площадки',
          'warning',
          false,
        )

      this.isTouched = true
      if (!this.validateData()) {
        window.scrollTo(0, this.$refs.basketFormRef.offsetTop)
        return
      }

      try {
        this.$store.commit('SET_LOADING', true)
        const firstName = this.fullName.split(' ')[0]
        const lastName = this.fullName.split(' ')[1]
        const phone = this.phone.replace(/[+() ]/g, '')
        const createOrderBody = {
          addressId: this.selectedAddress.id,
          firstName,
          lastName,
          phone,
          deliveryType: this.selectedDeliveryType,
        }
        if (this.email) createOrderBody.email = this.email
        if (this.promoCode && this.promoPrice) createOrderBody.promo = this.promoCode

        await this.wrapAndHandleRequest(() => Api.createOrder(createOrderBody), false)
        let trans = null
        this.wrapAndHandleRequest(() => Api.addTransaction({ region: Number(this.isDeliveryZoneRegion) }), false).then((e) => {
          console.log('e.transaction.id')
          console.log(e.transaction.id)
          console.log(e)
          this.payy(e.transaction.id, e.transaction.amount)
        })
        console.log('trans')
        console.log(trans)

        // const { url } = await this.wrapAndHandleRequest(Api.userPay, false)
        //this.payUrl = url
        //this.$nextTick(() => {
        // this.$refs.payLink.click()
        // this.$nextTick(() => this.$router.push({ name: 'layout.main' }))
        //  })
      } finally {
        this.$store.commit('SET_LOADING', false)
      }
    },

    async getOrderDeliveryDateAndPrice() {
      if (!this.selectedDeliveryType) {
        this.deliveryDate = 0
        this.deliveryPrice = 0
        return
      }
      const deliveryType = `deliveryType=${this.selectedDeliveryType}`
      const cart = JSON.stringify(
        this.$store.state.products.basket.map((el) => {
          return {
            leftover_id: el.leftover_id,
            quantity: el.quantity,
          }
        }),
      )
      const deliveryPriceBody = {
        cart,
        deliveryType: this.selectedDeliveryType,
        region: Number(this.isDeliveryZoneRegion),
      }
      const { delivery_amount } = await this.wrapAndHandleRequest(() => Api.getDeliveryPrice(deliveryPriceBody), false)
      const { date } = await this.wrapAndHandleRequest(() => Api.getDeliveryDate(deliveryType), false)
      this.deliveryDate = date
      this.deliveryPrice = delivery_amount
    },
  },

  watch: {
    isDeliveryZoneRegion() {
      this.getOrderDeliveryDateAndPrice()
    },

    selectedDeliveryType() {
      this.getOrderDeliveryDateAndPrice()
    },
  },
}
</script>

<style>
.list-group-item.active {
  background: #66a91a;
  border-color: #66a91a;
}
</style>
